import React, { useState, useEffect, useRef } from 'react';

import Tridi from 'react-tridi';
import 'react-tridi/dist/index.css';

const preloadImages = (srcArray) => {
	srcArray.forEach(src => {
	  const img = new Image();
	  img.src = src;
	});
  };

const App = () => {
	const imageCount = 36;
	const imageFolder = './images1000';
	const imageFormat = 'webp';
	const imageSrcs = Array.from({ length: imageCount }, (_, index) => `${imageFolder}/${index + 1}.${imageFormat}`);
  
	useEffect(() => {
	  preloadImages(imageSrcs);
	}, []);

	const [isAutoPlayRunning, setIsAutoPlayRunning] = useState(false);
	const [pins, setPins] = useState([]);
	const tridiRef = useRef(null);

	const frameChangeHandler = (currentFrameIndex) => {
		console.log('current frame index', currentFrameIndex);
	};

	const recordStartHandler = (recordingSessionId) =>
		console.log('on record start', { recordingSessionId, pins });

	const recordStopHandler = (recordingSessionId) =>
		console.log('on record stop', { recordingSessionId, pins });

	const pinClickHandler = (pin) => {
		console.log('on pin click', pin);
		tridiRef.current.toggleRecording(true, pin.recordingSessionId);
	};

	const onLoadChange = (loaded, percentage) =>{
		console.log('have all Image loaded? : '+ loaded);
		console.log('current load percentage : ' + percentage + "%");
	}

	return (
		<div style={{ width: '1000px' }}>
			<Tridi
				ref={tridiRef}
				location="./images3"
				format="webp"
				count="36"
				onFrameChange={frameChangeHandler}
				autoplaySpeed={70}
				onAutoplayStart={() => setIsAutoPlayRunning(true)}
				onAutoplayStop={() => setIsAutoPlayRunning(false)}
				onRecordStart={recordStartHandler}
				onRecordStop={recordStopHandler}
				onPinClick={pinClickHandler}
			    inverse
				showControlBar
				showStatusBar
				mousewheel
				// pins={pins}
			//	setPins={setPins}
				hintOnStartup
				hintText="Drag to view"
				onLoadChange = {onLoadChange}
			/>

			<button onClick={() => tridiRef.current.prev()}>Prev</button>
			<button onClick={() => tridiRef.current.next()}>Next</button>
			<button onClick={() => tridiRef.current.toggleAutoplay(!isAutoPlayRunning)}>
				{isAutoPlayRunning ? 'Pause' : 'Autoplay'}
			</button>
		</div>
	);
};

export default App;
